nav.navbar-light.nav-main {
	padding: 2.5rem 0;
	box-shadow: $shadow-2;
	a.navbar-brand {
		padding: 0;
		font-size: 0;
		position: relative;
		> img {
			position: relative;
			z-index: 1; } }
	.navbar-collapse {
		justify-content: space-between;
		margin-left: 3rem;
		&.show.collapse {
			padding: 1.5rem;
			margin-left: 0;
			li {
				> a.nav-link {
					padding: 1.5rem 0; } } }
		ul > li, input, ul>li>a {
			font-size: 1.4rem; }
		ul.navbar-nav {
			a.nav-link {
				padding: 1rem 2rem;
				display: flex;
				align-items: center;
				border-radius: 0px;
				transition: .3s ease all;
				span {
					position: relative; }
				&:not(:last-child) {
					margin-right: 1rem; }
				&:hover, &:focus {
					color: $color-green-2;
					& {} }
				&.btn-outline-main {
					&:hover, &:focus {
						color: $color-white; } }
				&.active {
					color: $color-green-2;
					span {
						&::after {
							content: "";
							position: absolute;
							bottom: -.5rem;
							left: 0;
							height: 1.5px;
							width: 100%;
							background: $color-green-2; } } } } }
		.dropdown-menu {
			min-width: 150px;
			text-align: center;
			box-shadow: $shadow-2;
			border: none;
			padding: 1.5rem;
			> li {
				margin-bottom: .5rem;
				> a {
					&:hover, &:focus {
						background-color: unset;
						color: $color-green-2; } } } } } }
.login {
	color: $color-main;
	@include poppins(R, 1.4rem); }
.avatar-header {
	display: flex;
	align-items: center;
	.name {
		margin-right: 1rem; }
	img {
		width: 4rem;
		height: 4rem; } }
