.info-avatar {
    position: relative;
    margin-bottom: 2rem;
    .avatar {
        position: relative;
        > img {
            width: 160px;
            height: 160px;
            border-radius: 99%; } }
    .edit-avatar {
        position: absolute;
        right: 25%;
        bottom: 0;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        border-radius: 99%;
        box-shadow: $shadow-1;
        > .ic-camera {
            color: $color-blue; } } }
.profile__box.contributor {
    .info-avatar {
        .avatar {
            img {
                @media (max-width: 991px) {
                    width: 120px;
                    height: 120px; } } }
        .edit-avatar {
            @media (max-width: 991px) {
                right: 35%; }
            @media (max-width: 480px) {
                right: 25%; } } } }
.info-user {
    .d-flex {
        margin-bottom: 1rem;
        p {
            @include poppins(R, 1rem);
            color: $gray;
            &:first-child {
                margin-right: 0.5rem; } } } }

.practice {
    &__heading, &__box {
        margin-bottom: 2rem;
        width: 100%;
        p {
            @include poppins(R, .8rem);
            color: $color-black;
            @media (max-width: 767px) {
                @include poppins(R, .6rem); } }
        .prCol-4 {
            width: 40%;
            h3 {
                margin-bottom: 0;
                @media (max-width: 767px) {
                    font-size: 1.4rem; } } }
        .prCol-3 {
            width: 30%; }
        .prCol-2 {
            width: 20%; }

        .prCol-1 {
            width: 10%; } }
    &__heading {
        .btn-add {
            @include poppins(B, 1.2rem);
            height: 40px;
            &:hover, &:focus {
                color: $white; }
            @media (max-width: 767px) {
                font-size: .6rem;
                padding: 0; } } }
    &__item {
        svg {
            min-width: 2.4rem; }
        p {
            @include poppins(R, 1.4rem);
            width: 200px;
            @media (max-width: 991px) {
                margin-left: .5rem !important;
                width: 150px; }
            @media (max-width: 576px) {
                @include poppins(R, 1rem);
                margin-left: .5rem !important;
                width: 100px; } } }
    &__box {
        margin-bottom: 2rem;
        .btn-edit {
            @include poppins(R, 1.2rem);
            @media (max-width: 576px) {
                @include poppins(R, 1rem); } }
        .prCol-2, .prCol-1, .prCol-3 {
            p {
                color: $gray; } } }

    &__wrap {
        margin-bottom: 4rem;
        width: 100%; }
    &__list {
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .practice {
            width: 80%;
            @media (max-width: 576px) {
                width: 90%; }
            span {
                &.percent {
                    width: 10%;
                    @include poppins(B, 1.4rem);
                    color: $color-blue;
                    @media (max-width: 576px) {
                        @include poppins(B, 1rem); } }
                &.timeCreated {
                    @include poppins(R, .8rem);
                    color: $gray;
                    @media (max-width: 576px) {
                        @include poppins(R, .6rem); } } }
            .practice__item {
                width: 50%; } }
        .btn {
            font-size: 1.2rem;
            @media (max-width: 576px) {
                padding: 0 20px;
                font-size: 1rem; } } } }
.profile__box {
    background: $white;
    border-radius: 1.6rem;
    padding: 4rem;
    @media (max-width: 576px) {
        padding: 2rem; }
    .form-default, .form-column {
        height: 100%;
        width: 100%; }
    .form-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }

    .form-default {
        padding-right: 2rem;
        @media (max-width: 991px) {
            margin-bottom: 2rem; }
        @media (max-width: 576px) {
            padding-right: 0; }
        .m-label {
            @include poppins(B, 1.4rem);
            color: $color-black;
            @media (max-width: 576px) {
                font-size: 1.2rem; } }
        input.form-title {
            @include poppins(R, 3rem);
            @media (max-width: 576px) {
                @include poppins(R, 1.8rem); } } } }
.line-form {
    margin-bottom: 2.5rem;
    &.file-img {
        @media (max-width: 767px) {
            flex-wrap: wrap; }
        > .m-label {
            @media (max-width: 767px) {
                margin-bottom: 1rem; } } }
    &.categories {
        .accordion-button, .accordion-link {
            @media (max-width: 576px) {
                font-size: 1.2rem; } } }
    input {
        font-size: 1.4rem;
        @media (max-width: 576px) {
            font-size: 1.2rem; } } }
.input {
    display: flex;
    label {
        width: 15%; }
    input {
        width: 85%; } }


.form-default, .form-column {
    label {
        @include poppins(R, 1.4rem);
        color: $color-base;
        @media (max-width: 576px) {
            font-size: 1.2rem; } }
    input {
        color: $color-black; }
    h6.m-label {
        margin-bottom: 1.5rem;
        @include poppins(B, 1.4rem);
        color: $color-black;
        @media (max-width: 576px) {
            font-size: 1.2rem; } }
    .sub-form {
        margin-bottom: 1rem; }
    .form-check-input[type=checkbox] {
        width: 1.6rem;
        height: 1.6rem;
        min-width: 1.6rem;
        border: 1px solid $color-black;
        border-radius: 0;
        &:checked {
            border: none;
            background-color: $color-light-blue; } }
    .file-input {
        position: relative; }
    .upload-file {
        position: absolute;
        right: 0;
        opacity: 0;
        filter: alpha(opacity=0); }
    .file-name {
        font-size: 1.4rem;
        margin-right: 2rem;
        @media (max-width: 576px) {
            font-size: 1.2rem; } } }


.question__box {
    border: 1px solid $color-gray;
    border-radius: .8rem;
    padding: 1.5rem;
    margin-bottom: 2rem;
    .form-question {
        display: flex;
        margin-bottom: 2.5rem;
        label {
            width: 30%; }
        input {
            width: 70%;
            font-size: 1.4rem;
            @media (max-width: 576px) {
                font-size: 1.2rem; } }
        select {
            width: 35%;
            @media (max-width: 576px) {
                width: 60%;
                font-size: 1.2rem; }
            option {
                @media (max-width: 576px) {
                    font-size: 1.2rem; } } } } }

.fr-box.fr-basic {
    p {
        @media (max-width: 576px) {
            font-size: 1.2rem; } } }

.easypie-data {
    @include poppins(B, 3rem);
    color: $color-blue;
    @media (max-width: 576px) {
        @include poppins(B, 1.8rem); } }
