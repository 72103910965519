

@media (min-width: 320px) {
  

    .introduce-box { 
      
    }

}

@media (min-width: 992px) {
  
}

@media (max-width: 1199px) { 
    .success-story__sect { 
        .intro-content {
            padding: 0 5rem
        } 
    }
}

@media (max-width: 991px) {
    .introduce-wrapper { 
        margin-left: 0;
        margin-top: 3rem
    }
}


@media (max-width: 767px) {
   
    .banner-main__sect { 
        .slogan  { 
            @include poppins(B, 4.0rem) 
        }
        .banner-row { 
            padding: 0 4rem
        }
    }
    
}

@media (max-width: 576px) {
    span.word {
        padding: 1rem;
    }
}

@media (max-width: 480px) { 
    .banner-main__sect {
        .banner-row { 
            padding: 0 1.5rem
        }
    }
}