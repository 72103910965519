.swiper-introduce, .swiper-story {
    .swiper-pagination {
        bottom: 18%;
        .swiper-pagination-bullet {
            background-color: $gray; }
        .swiper-pagination-bullet-active {
            background-color: $color-blue; } } }
.swiper-story {
    padding-bottom: 4rem;
    .swiper-pagination {
        &.story-pagination {
            bottom: 0; }
        .swiper-pagination-bullet-active {
            background-color: $white; } } }
.swiper-news-1, .swiper-news-2, .swiper-news-3 {
    .swiper-pagination {
        bottom: 15px;
        .swiper-pagination-bullet {
            background-color: $gray; }
        .swiper-pagination-bullet-active {
            background-color: $color-blue; } } }

span.word {
    border-radius: .8rem;
    background: $color-gray-3;
    padding: 1rem 2rem;
    @media (max-width: 576px) {
        padding: 1rem; }
    &.det {
        color: $color-orange; }
    &.noun {
        color: $color-main;
        background: $color-bg-green; }
    &.verb {
        color: $color-red;
        background: $color-bg-red; } }
span {
    &.debt-word {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 100%;
            height: 3px;
            background: $color-blue;
            @media (max-width: 767px) {
                bottom: 0;
                height: 2px; } } }
    &.noun-word {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 100%;
            height: 3px;
            background: $line-green;
            @media (max-width: 767px) {
                bottom: 0;
                height: 2px; } } }
    &.verb-word {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 100%;
            height: 3px;
            background: $color-orange;
            @media (max-width: 767px) {
                bottom: 0;
                height: 2px; } } } }
.ai-assistant {
    &__bg {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../images/homepage/ss_1.svg);
            width: 318px;
            height: 157px;
            z-index: 0; }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            background-image: url(../images/homepage/ss_2.svg);
            width: 806px;
            height: 397px;
            z-index: 0; }
        .breadcumb__sect {
            position: relative;
            z-index: 1; } }
    &__sect {
        position: relative;
        z-index: 1;
        padding: 10rem 0;
        @media (max-width: 767px) {
            padding: 5rem 0 10rem; }
        .n-title {
            margin-bottom: 8rem;
            @media (max-width: 767px) {
                margin-bottom: 4rem; } } }
    &__box {
        position: relative;
        z-index: 1; }
    &__wrapper {
        max-width: 1044px;
        margin: 0 auto;
        background: $white;
        box-shadow: $shadow-1;
        border-radius: 1.6rem;
        padding: 8rem;
        position: relative;
        @media (max-width: 991px) {
            padding: 6rem 4rem; }
        @media (max-width: 576px) {
            padding: 4rem 2rem; }
        &::before {
            content: "";
            position: absolute;
            top: -12%;
            left: -8%;
            background-image: url("../images/ai_assistant.svg");
            background-repeat: no-repeat;
            z-index: -1;
            width: 345px;
            height: 230px; }
        &::after {
            content: "";
            position: absolute;
            bottom: -22%;
            right: -4%;
            background-image: url("../images/ai_assistant_1.svg");
            background-repeat: no-repeat;
            z-index: -1;
            width: 345px;
            height: 230px;
            @media (max-width: 767px) {
                right: -20%; } }
        .choose-area {
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6rem; }
        .area {
            .area-item {
                @include poppins(R, 1.6rem);
                color: $color-light-blue;
                @media (max-width: 991px) {
                    @include poppins(R, 1.4rem); }
                @media (max-width: 767px) {
                    @include poppins(R, 1rem); }
                &.active {
                    @include poppins(B, 2.2rem);
                    @media (max-width: 991px) {
                        @include poppins(B, 1.6rem); }
                    @media (max-width: 767px) {
                        @include poppins(B, 1.2rem); } } } }
        .analyze-box {
            margin-bottom: 6rem;
            justify-content: center;
            @media (max-width: 576px) {
                flex-wrap: wrap;
                margin-bottom: 4rem; }
            .analyze-word {
                margin-right: 1.5rem;
                text-align: center;
                @media (max-width: 576px) {
                    margin-bottom: 1rem; }
                span {
                    display: block;
                    &:first-child {
                        @include poppins(R, 4rem);
                        margin-bottom: 1rem;
                        @media (max-width: 991px) {
                            font-size: 3rem; }
                        @media (max-width: 767px) {
                            font-size: 2rem; }
                        @media (max-width: 576px) {
                            font-size: 1.6rem; } }
                    &:last-child {
                        display: inline-block;
                        @include poppins(B, 2.2rem);
                        @media (max-width: 991px) {
                            font-size: 1.6rem; }
                        @media (max-width: 767px) {
                            font-size: 1.4rem; }
                        @media (max-width: 576px) {
                            font-size: 1.2rem; } } } } }

        .subcribe {
            margin: 0 auto;
            margin-bottom: 6rem;
            width: 50%;
            @media (max-width: 991px) {
                width: 60%; }
            @media (max-width: 767px) {
                width: 100%;
                padding: 0; } }
        .fill-word__form {
            align-items: flex-end;
            margin-bottom: 5rem;
            .form-inline {
                font-size: 4.0rem;
                width: 25%;
                border-bottom-color: #ffffff;
                color: $color-black;
                @media (max-width: 991px) {
                    font-size: 3rem;
                    width: 30%; }
                @media (max-width: 767px) {
                    font-size: 2rem; }
                @media (max-width: 576px) {
                    font-size: 1.6rem; }
                &::placeholder {
                    color: #E0E0E0; }
                &:disabled {
                    color: #000000;
                    width: 30%; }
                &.center {
                    width: 10%;
                    border-bottom: 2px solid $color-main;
                    @media (max-width: 767px) {
                        width: 15%; } } }

            .main-line {
                width: 15%;
                height: 3px;
                background: $color-main;
                @media (max-width: 767px) {
                    height: 2px; } }
            .btn-form-blue {
                font-size: 1.6rem;
                width: 20%;
                @media (max-width: 767px) {
                    font-size: 1.2rem; } } }
        .box-match {
            padding: 1.5rem 3rem; }
        .box-word {
            font-size: 4rem;
            margin-bottom: 0;
            @media (max-width: 767px) {
                font-size: 2rem; }
            @media (max-width: 576px) {
                font-size: 1.6rem; } }
        .submit-form {
            margin-bottom: 6rem;
            @media (max-width: 767px) {
                margin-bottom: 4rem; } }
        .subcribe {
            .btn-form-blue {
                font-size: 1.2rem;
                @media (max-width: 576px) {
                    font-size: 1rem; } } }
        .fill-text {
            font-size: 4.0rem;
            @media (max-width: 991px) {
                font-size: 3rem; }
            @media (max-width: 767px) {
                font-size: 2rem; }
            @media (max-width: 576px) {
                font-size: 1.6rem; } }
        .write {
            max-width: 60%;
            text-align: center; }
        .swiper-slide {
            &.swiper-slide-active {
                @media (max-width: 767px) {
                    text-align: center; }
                .area-item {
                    @include poppins(B, 2.2rem);
                    @media (max-width: 991px) {
                        @include poppins(B, 1.6rem); }
                    @media (max-width: 767px) {
                        @include poppins(B, 1.4rem); } } }
            &.swiper-slide-next {
                text-align: right; } } } }

