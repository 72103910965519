/* #Page:Reset
================================================== */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* Stops flash on tap iOS */
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    border: 0;
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

// ol,
// ul,
// ul li {
//     list-style: none
// }

body {
    overflow-x: hidden;
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

a {
    color: #141414;
    outline: 0;
    cursor: pointer;
    &:hover {
        // color: shade($color-link, 20%);
        text-decoration: none;
        color: #141414;
        cursor: pointer;
    }
}

p {
    margin: 0
}

strong {
    font-weight: bold
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: inherit
}

h1 {
    font-size: 4em
}

h2 {
    font-size: 3em
}

h3 {
    font-size: 2.5em
}

h4 {
    font-size: 2.2rem
}

h5 {
    font-size: 1.8rem
}

h6 {
    font-size: 1.6rem
}

img.responsive-img,
video.responsive-video {
    display: block;
    max-width: 100%;
    height: auto
}

label {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none
}

ul,
ol,
ul ul li,
ul ol li,
ol ol li,
ol ul li,
form,
fieldset,
.alert>p,
.alert>ul {
    margin-bottom: 0
}

li,
ul.large li,
li p {
    line-height: $line-height
}

a,
img,
span,
label,
li {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
}

select.form-select, a, select option {
	cursor: pointer;
	font-size: 1.4rem;
}

.clearfix:before,
.clearfix:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0
}

.clearfix {
    zoom: 1
}

.fl {
    float: left
}

.fr {
    float: right
}

.clear,
.clearfix:after {
    clear: both
}


.userSelect-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}


button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}


/* Bootstrap style */

.tab-content>.tab-pane {
	display: none
}

.tab-content>.active {
	display: block
}

.open .popover {
	display: block
}

// .dropdown {
// 	display: inline-block;
// 	position: relative
// }

// .dropdown-menu {
// 	z-index: -1;
// 	visibility: hidden;
// 	@include opacity(0)
// }

// .open .dropdown-menu {
// 	display: block;
// 	z-index: 1;
// 	visibility: visible;
// 	@include opacity(1);
// 	@include transition(all 0.25s ease-in-out)
// }

.collapse.in {
	display: block
}



.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition: height .35s ease;
	transition: height .35s ease
}

.alert {
	margin-bottom: 20px;
	border: 1px solid transparent;
	padding: 6px 10px;
	font-size: 0.875rem
}

.alert h4 {
	margin-top: 0;
	color: inherit
}

.alert .alert-link {
	font-weight: 700
}

.alert-dismissable {
	padding-right: 35px
}

.alert-dismissable .close {
	position: relative;
	top: -2px;
	right: -21px;
	color: inherit
}

.alert-success {
	color: #66cc00;
	background-color: #dff0d8;
	border-color: #d6e9c6
}

.alert-success hr {
	border-top-color: #c9e2b3
}

.alert-success .alert-link {
	color: #66cc00
}

.alert-info {
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bce8f1
}

.alert-info hr {
	border-top-color: #a6e1ec
}

.alert-info .alert-link {
	color: #245269
}

.alert-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faebcc
}

.alert-warning hr {
	border-top-color: #f7e1b5
}

.alert-warning .alert-link {
	color: #66512c
}

.alert-danger {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1
}

.alert-danger hr {
	border-top-color: #e4b9c0
}

.alert-danger .alert-link {
	color: #843534
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

a, button {
	transition: .3s ease all;
}

a:hover, a:focus, input:focus, button:hover, button:focus, select:focus {
	outline: none !important;
	box-shadow: none !important;
	border: none;

}

input.form-control:focus {
	border: 1px solid $color-gray
}



.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg)
}

.accordion-button::after, .accordion-button.active::after {
    transform: rotate(-90deg)
}