h3.n-title {
    @include poppins(B, 3.2rem);
    text-align: center;
    margin-bottom: 4rem;
    color: $color-blue;
    @media (max-width: 767px) {
        font-size: 2rem;
        margin-bottom: 2.5rem; } }
.banner-main__sect, .about__sect, .mentor__sect, .new-community__sect, .member_sect, .success-story__sect {
    padding: 10rem 0;
    @media (max-width: 767px) {
        padding: 5rem 0; } }

.banner-main__sect {
    .slogan {
        @include poppins(B, 6.0rem); }
    .banner-row {
        padding: 0 8rem; }
    p.my-5 {
        @include poppins(R, 2.0rem);
        @media (max-width: 767px) {
            font-size: 1.6rem; } } }
.introduce-wrapper {
    margin-left: 13rem; }
.introduce-box {
    border-radius: 1.6rem;
    background-color: $white;
    box-shadow: $shadow-1;
    min-height: 510px;
    min-width: 330px;
    @extend %d-flex-center;
    text-align: center;
    position: relative;
    overflow: hidden;

    &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/homepage/deco_1.svg);
        width: 144px;
        height: 116px; }
    &::after {
        top: unset;
        left: unset;
        bottom: 0;
        right: 0;
        background-image: url(../images/homepage/deco_2.svg);
        width: 225px;
        height: 195px; }

    h6 {
        margin-bottom: 3rem;
        @include poppins(B, 2.2rem); }

    .quote {
        color: $gray; } }
.box-word {
    color: $color-blue;
    display: inline-block;
    padding: .75rem .75rem;
    margin: 0 .25rem;
    background-color: $color-main;
    border-radius: 4px;
    margin-bottom: 1.5rem; }
.box-match {
    display: inline-block;
    border: 2px solid $color-gray;
    border-radius: 4px;
    padding: 1.5rem;
    margin-bottom: 6rem;
    &:hover {
        border: 2px solid $color-gray; }
    p {
        @include poppins(R, 1.2rem);
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0; } }
    .match-word {
        color: $color-main;
        @include poppins(B, 1.6rem); } }

.box-ielts {
    background-color: $color-gray-1;
    border-radius: 1.6rem;
    padding: 3rem;
    @media (max-width: 991px) {
        margin-bottom: 1.5rem; }
    @media (max-width: 767px) {
        margin: 0 2rem;
        margin-bottom: 3rem; }

    .box-title {
        color: $color-green-2;
        @include poppins(B, 1.6rem);
        min-height: 48px;
        line-height: 24px;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        letter-spacing: 1px;
        margin-bottom: 1.5rem; }
    p {
        @include poppins(R, 1.2rem);
        color: $color-base; } }
.success-story__sect {
    background-color: $color-main;
    position: relative;
    z-index: 0;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/homepage/ss_1.svg);
        width: 318px;
        height: 157px;
        z-index: 0; }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: url(../images/homepage/ss_2.svg);
        width: 806px;
        height: 397px;
        z-index: 0; }

    .container {
        position: relative;
        z-index: 1000; }
    .success__wrap {
        @media (max-width: 767px) {
            margin: 0 1.5rem; } }
    .intro-content {
        position: relative;
        padding: 0 12rem;
        margin-bottom: 2rem;
        @media (max-width: 767px) {
            padding: 0 6rem; }

        &:after, &:before {
            content: "";
            position: absolute;
            background-image: url(../images/dot_1.svg);
            width: 79px;
            height: 58px;
            top: 0;
            left: 0;
            @media (max-width: 767px) {
                background-size: cover;
                width: 38px;
                height: 30px; } }
        &:after {
            top: unset;
            left: unset;
            bottom: 0;
            right: 0;
            background-image: url(../images/dot_2.svg);
            @media (max-width: 767px) {
                background-size: cover;
                width: 42px;
                height: 30px; } }

        p {
            color: $color-blue;
            @include poppins(R, 1.2rem);
            line-height: 2.4rem;
            position: relative;
            z-index: 1; } }
    .student-success {
        color: $color-blue;
        margin-bottom: 3rem;
        h6 {
            @include poppins(B, 1.6rem);
            margin-bottom: .5rem;
            > span {
               font-weight: normal; } }
        p {
            @include poppins(L, 1.2rem); } }


    .student-img {
        margin: 0 2rem;
        > img {
            border-radius: 16px; } } }
.see-more {
    color: $white;
    transition: .3s ease all;
    &:hover, &:focus {
        color: $white; } }
.box-member__wrap, .box-mentor__wrap {

    @media (max-width: 991px) {
        margin-bottom: 1.5rem; }
    @media (max-width: 767px) {
        margin: 0 1.5rem;
        margin-bottom: 2rem; }
    .fix-img {
        padding-bottom: 64%; }
    .box-content {
        border: 2px solid $color-gray;
        border-radius: 8px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 2rem;
        color: $color-blue; }
    .info {
        margin-bottom: .5rem; }
    .score-box {
        margin-bottom: 1rem; }
    p.master {
        color: $color-base;
        max-width: 60%; }
    p.total-score {
        color: $color-main; }
    h6.name {
        @include poppins(B, 1.2rem); }
    a.link {
        margin-right: .5rem; } }
.new-community__sect {
    background-color: $color-gray-3; }


.community-box__wrap {
    border-radius: 1.6rem;
    background-color: $white;
    color: $color-black;
    min-height: 600px;
    padding-bottom: 2rem;
    @include poppins(R, 1.2rem);
    color: $color-black;
    @media (max-width: 991px) {
        margin-bottom: 2.5rem; }
    @media (max-width: 767px) {
        margin: 0 1.5rem;
        margin-bottom: 2rem; }
    .header {
        padding: 2rem;
        padding-bottom: 0; }
    .logo {
        align-items: center; }
    .info-logo {
        margin-top: 5px;
        padding-left: 8px;
        h6 {
            @include poppins(semiB, .8rem);
            line-height: 0; }
        span {
            @include poppins(L, .4em); } }
    .fix-img {
        padding-bottom: 65%; }
    .com-content {
        padding: 2rem;
        .status {
            margin-bottom: 1rem;
            &.no-img {
                margin-bottom: 3rem;
                margin-top: 6rem; } } }
    p {
        @include poppins(L, 1rem); }

    .react {
        @include poppins(L, .6rem);
        display: block;
        margin-bottom: 1rem; }
    .reaction {
        @extend %col-align-center; }
    .converstation, .status {
        .d-flex {
            p:first-child {
                width: 30%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
            p:last-child {
                width: 70%; } }
        .convers {
            margin-bottom: 1rem; } }
    a.s-more {
        color: $color-main;
        @include poppins(L , .8rem);
        display: block;
        transition: .3s ease all;
        margin-bottom: 1rem; }
    span.pinned {
        @include poppins(L, .6rem);
        color: $gray;
        justify-content: flex-end;
        align-items: center; }
    .btn.btn-s {
        font-size: 1.2rem; } }


.subcribe {
    margin-top: 10rem;
    width: 45%;
    @media (max-width: 991px) {
        width: 60%; }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0 3rem;
        margin-top: 5rem; } }


.message-chat__wrapper {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    max-width: 380px;
    min-width: 330px;
    background: $white;
    box-shadow: $shadow-1;
    border-radius: 1.6rem;
    z-index: 99999;
    .message-box {
        p {
            @include poppins(R, 1rem); } }
    .header {
        border-bottom: 1px solid #e0e0e0;
        padding: 2rem;
        img {
            width: 16px;
            height: 16px; }
        .info-logo {
            margin-left: 1rem; }
        h6 {
            @include poppins(semiB, 1.4rem); }

        p {
            @include poppins(L, .7rem); }
        span.circle {
            width: 6px;
            height: 6px;
            border-radius: 99%;
            display: inline-block;
            margin-right: .5rem;
            &.green {
                background: $color-main; } } }
    .message-body {
        padding: 2rem; }
    .chatting {
        width: 80%;
        margin-bottom: 1.5rem;
        &.right {
            text-align: right;
            width: 100%;
            .avatar {
                text-align: right; }
            .content-box {
                margin-left: 80%;
                text-align: right;
                background:  #F2F2F2;
                border-radius: 6px 6px 0px; } }

        .avatar {
            img {
                width: 16px;
                height: 16px; } }
        .name {
            @include poppins(semiB, .8rem);
            padding-left: .5rem; }
        .content-box {
            background:  #F2F2F2;
            border-radius: 6px 6px 0px;
            padding: 1rem; } }
    .message-footer {
        .subcribe {
            width: 100%;
            padding: 0 2rem;
            margin-top: 0rem;
            .form-control {
                min-height: 40px; }
            .form-control,.btn-form-blue {
                font-size: 1rem; } } } }
