//-- Placeholders
%full-container {
	max-width: 1260px;
	margin: 0 auto;
	width: 100%;
	@media (max-width: 1399px) {
		max-width: 1170px; }
	@media (max-width: 1199px) {
		max-width: 960px; }
	@media (max-width: 991px) {
		max-width: 720px; }
	@media (max-width: 767px) {
		max-width: 540px; }
	@media (max-width: 576px) {
		max-width: none; }
	@media (max-width: 480px) {
		max-width: none; } }
%d-flex {
	display: flex; }
%just-center {
	display: flex;
	justify-content: center; }
%align-center {
	display: flex;
	align-items: center; }
%d-flex-wrap {
	display: flex;
	flex-wrap: wrap; }
%d-flex-center {
	display: flex;
	justify-content: center;
	align-items: center; }
%d-flex-center-between {
	display: flex;
	justify-content: space-between;
	align-items: center; }
%inline-flex-center {
	display: inline-flex;
	justify-content: center;
	align-items: center; }
%align-end {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end; }
%flex-column {
	display: flex;
	justify-content: center;
	flex-direction: column; }
%col-align-center {
	display: flex;
	flex-direction: column;
	align-items: center; }
%title-in-profile {
	// text-transform: uppercase
	display: block;
	position: relative;
	// height: 46px
	line-height: 36px;
	color: #141414;
	font-size: 1.5em;
	font-weight: 700;
	@media (max-width: 991px) {
		font-size: 1.25em; } }
%sub-title {
	display: block;
	color: #a9aaad;
	font-size: 1em;
	font-weight: 700;
	text-transform: uppercase;
	@media (max-width: 767px) {
		font-size: .75em; } }
%discount-text {
	background-color: rgba(255, 53, 1, .8);
	padding: 3px 10px;
	color: #fff;
	font-size: .875em;
	border-radius: 20px; }
%label-pos {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 2;
	display: flex;
	flex-flow: column;
	align-items: flex-end; }
%label-span {
	background-color: rgba(0,0,0,.8);
	padding: 6px 10px;
	color: #fff;
	font-size: .875em;
	border-radius: 20px;
	line-height: 24px; }
%op-background {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0,0,0,.25);
	width: 100%;
	height: 100%;
	z-index: 0; }
%pseudo-e {
	content: "";
	position: absolute;
	top: 0;
	left: 0; }
%img-ratio {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; }
%i-chevron-down {
	display: block;
	width: 15px;
	height: 15px;
	background: none;
	margin: 0 auto;
	transform: rotate(45deg);
	border-left: none;
	border-top: none;
	border-right: 2px #fff solid;
	border-bottom: 2px #fff solid; }

%red-dot {
	position: absolute;
	top: 12px;
	right: 12px;
	// box-shadow: 0 0 5px 0 #fc4a4a
	content: "";
	background-color: $color-error;
	width: 7px;
	height: 7px;
	@include radius(100%); }

%config-background {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover; }

%overlay-bg {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: hsla(0,0%,100%,.75); }

%close-btn {
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1;
	color: #6c6c6e;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
	position: absolute;
	top: 20px;
	right: 25px;
	padding: 1rem;
	margin: -1rem -1rem -1rem auto; }

%picktime-options {
	position: absolute;
	border-top: 1px solid #00a550;
	top: 100%;
	width: 100%;
	left: 0;
	padding: 20px 15px;
	max-height: 360px;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 99;
	background: #141414;
	box-shadow: 1px 4px 4px rgba(0,0,0,.2); }
%host-stars-avatar {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2;
	height: 30px;
	width: 30px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%; }

%circle-tag {
	position: absolute;
	top: 1.5rem;
	left: 1.5rem;
	width: 25px;
	height: 25px;
	border-radius: 99%;
	background: $color-theme; }
