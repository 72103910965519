.sb-reading {
    background: $white;
    border-radius: 1.6rem;
    padding: 3.2rem 2.4rem;
    padding-bottom: 6rem; }
.ct-reading {
    padding-left: 8rem;
    background: $white;
    border-radius: 1.6rem;
    padding: 9rem 3.1rem;
    .strategies {
        p {
            @include poppins(R, 1.4rem); } } }
.story-reading, .questions__wrap, .speaking-part, .listening-script {
    p {
        @include poppins(R, 1.4rem);
        line-height: 28px;
        color: $color-base; } }
.story-reading {
    margin-right: 2rem;

    p {
        margin-bottom: 2rem; }
    img {
        width: 100%;
        margin-bottom: 2rem; }
    &.writing {
        p {
            margin-bottom: .5rem; } } }

.questions {
    &__wrap {
        margin-left: 2rem;
        margin-top: 4.5rem;
        @media (max-width: 991px) {
            margin-left: 0;
            margin-top: 2rem; }
        .btn.facebook {
            @include poppins(R, 1.6rem); } }
    &__common, &__box, &__vocab {
        margin-bottom: 4rem; }
    &__common {
        &.story-reading {
            margin-right: 0; }
        h6 {
            margin-bottom: 1rem; } }
    &__box {
        input.line-dash {
            min-height: 32px;
            padding: 0rem 0 .75rem;
            font-size: 1.4rem; }
        .wrong {
            color: $color-red;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                height: 2px;
                width: 100%;
                left: 0;
                background: $color-red; } }

        .matched {
            color: $color-main;
            font-size: 1.4rem;
            position: relative; }
        .questions-item {
            @extend %d-flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 2rem;
            > p {
                margin-right: 1.5rem; }
            .answer {
                > p {
                    > span {
                        margin-right: .25rem; }
                    .matched {
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            height: 2px;
                            width: 100%;
                            left: 0;
                            background: $color-main; } } } } }
        &.answer__box {
            .questions-item {
                align-items: flex-start; }
            > p {
                margin-bottom: 2rem; } } } }

.speaking-part {
    > p {
        margin-bottom: 2rem; }
    .entry-ct {
        margin-bottom: 2rem;
        &.topic {
            margin-top: 5rem; }
        p {
            margin-bottom: .5rem; } } }

a.b-more {
    color: $color-light-blue;
    @include poppins(R , 1.4rem);
    display: block;
    transition: .3s ease all;
    margin-bottom: 1rem; }
