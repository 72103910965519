h4, h4.title {
	@include poppins(M, 1.6rem); }
h6.xs-title {
	@include poppins(B, 1.4rem);
	color: $color-base; }
.text-note {
	color: $color-gray-1 !important; }
.text-blue {
	color: $color-blue !important; }
.text-light-blue {
	color: $color-light-blue !important; }
.ic {
	font-size: 1.4rem;
	color: $color-base;
	margin-right: .5rem;
	&.ic-green {
		color: $color-main;
		font-size: 1.6rem; } }
.ic-medium {
	font-size: 1.8rem;
	color: $color-base;
	margin-right: 1rem; }
.ic-answer {
	font-size: 2.4rem; }
.ic-large {
	font-size: 3rem; }
.ic-social {
	font-size: 1.6rem;
	color: $color-blue; }
.ic-social-blue {
	font-size: 1.6rem;
	color: $color-light-blue; }
.ic-social-gray {
	font-size: 1.6rem;
	color: $color-gray-4; }
.ic-gray {
	color: $gray;
	font-size: 1.2rem; }
.ic-sm-green {
	color: $color-main;
	font-size: 1rem; }
.btn-outline-main {
	border: 2px solid $color-main;
	transition: .3s ease all;
	&:hover, &:focus {
		background: $color-main;
		color: $color-white;
		border: 2px solid $color-main; } }
.fix-img {
	position: relative;
	overflow: hidden;
	max-width: 100%;
	padding-bottom: 100%;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover; } }

.entry-content {
	p {
		margin-bottom: 2rem;
		text-align: justify; }
	.strong {
		font-weight: bold; } }

.line {
	width: 100%;
	height: 1px;
	background: $color-gray;
	margin-bottom: 1rem; }
.ic-img {
	width: 24px;
	height: 24px;
	@extend %d-flex-center; }
.w-90 {
	width: 90% !important; }
.w-80 {
	width: 80% !important; }
.w-70 {
	width: 70% !important; }
.w-60 {
	width: 60% !important; }
.w-50 {
	width: 50% !important; }
.w-40 {
	width: 40% !important; }
.w-30 {
	width: 30% !important; }
.w-20 {
	width: 20% !important; }
.w-10 {
	width: 10% !important; }

.red {
	color: $color-red !important; }
.green {
	color: $color-main !important; }


