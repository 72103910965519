
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.position-relative {
    position: relative
}

.min-height {
    min-height: 500px
}


/* Align */

.verticalAlign-middle {
    vertical-align: middle
}

.textAlign-center {
    text-align: center
}

.textAlign-left {
    text-align: left
}

.textAlign-right {
    text-align: right
}

.centered {
    margin: 0 auto
}


/* Space */

.space {
    @include padding(5px null)
}

.space.m {
    @include padding(10px null)
}

.space.l {
    @include padding(20px null)
}

.space.xl {
    @include padding(40px null)
}


/* Radius */

.borderRadius-s {
    @include radius(3px)
}

.borderRadius-m {
    @include radius(5px)
}

.borderRadius-l {
    @include radius(10px)
}

.borderRadius-circle {
    @include radius(100%)
}


/* Transform */

.textTransform-lowercase {
    text-transform: lowercase
}

.textTransform-uppercase {
    text-transform: uppercase
}

.textTransform-capitalize {
    text-transform: capitalize
}


/* Box shadow */

.boxShadow-distant {
    box-shadow: 0 2px 8px rgba(31, 45, 61, .05)
}

.boxShadow-close {
    box-shadow: 0 1px 2px 0 rgba(31, 45, 61, .15)
}

.boxShadow-subtle {
    box-shadow: 0 0 1px 1px rgba(31, 45, 61, .15)
}


/* Display */

.display-table {
    display: table;
    width: 100%
}

.display-tableCell {
    display: table-cell
}

.df-align-center {
	display: flex;
	align-items: center;
}

/* Width/ height */

.autoHeight {
    height: auto!important
}

.width-100 {
    width: 100%!important;
    max-width: 100%!important
}

.height-100 {
    height: 100%!important;
    max-height: 100%!important
}

.backgroundCover--center {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

/* Show - Hide */
.hidden {
    display: none!important
}

.block {
    display: block!important
}

.visuallyHidden {
    visibility: hidden
}

.visuallyShow {
    visibility: visible
}

.show-on-small {
	display: none !important;
}

input.form-control {
	background-color: $color-gray;
	border: none;
	padding: 1rem 2rem;
	padding-right: 35px;

}

.form-check-input:checked, .page-item.active .page-link {
	background-color: $color-main;
	border-color: $color-main;
}

.page-item .page-link {
	color: $color-main;
	border: none;
	font-weight: bold;
	text-transform: uppercase;
}

.fix-img {
	position: relative;
	max-width: 100%;
	overflow: hidden;
	padding-bottom: 100%;
	img {
		position: absolute;
		left: 0;
		top: 0; 
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}


.easypie {
    display: inline-flex;
    text-align: center;
    position: relative;
    .easypie-data {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 0;
        
    }
}

select.form-select {
    border-radius: 8px;
    border: 1px solid $color-gray-4;
    padding: 1rem 2rem;
    color: $color-black;
}
