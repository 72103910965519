/* Size scale */
html {
@include poppins(R, 10px);
  font-size: responsive;
  min-font-size: 7px;
  max-font-size: 10px;
}

p {
  @include poppins(R, 1.6rem);
}

h4 {
  @include poppins(B, 2rem);
}

.fontSize-xl {
  font-size: 40px;
}

.fontSize-l {
  font-size: 32px;
}

.fontSize-m {
  font-size: 16px;
}

.fontSize-s {
  font-size: 14px;
}

.fontSize-xs {
  font-size: 12px;
}
.font-8 {
  font-size: .8rem;
}
.font-10 {
  font-size: 1rem;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 1.2rem;
}
.font-13 {
  font-size: 1.3rem;
}
.font-14 {
  font-size: 1.4rem;
}
.font-16 {
  font-size: 1.6rem;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 1.8rem;
}
.font-20 {
  font-size: 2.0rem;
}
.font-24 {
  font-size: 2.4rem;
}
.font-28 {
  font-size: 2.8rem;
}
.font-32 {
  font-size: 3.2rem;
}
.font-36 {
  font-size: 3.6rem;
}
.font-40 {
  font-size: 4rem;
}

/* Fixed scale */

.fontWeight-1 {
  font-weight: 100;
}

.fontWeight-2 {
  font-weight: 200;
}

.fontWeight-3 {
  font-weight: 300;
}

.fontWeight-4 {
  font-weight: 400;
}

.fontWeight-5 {
  font-weight: 500;
}

.fontWeight-6 {
  font-weight: 600;
}

.fontWeight-7 {
  font-weight: 700;
}

.fontWeight-8 {
  font-weight: 800;
}

.fontWeight-9 {
  font-weight: 900;
}

/* Text color */

.text-white {
  color: #ffffff;
}

.text-primary {
  color: $color-blue !important;
}
.text-blue {
  color: $color-light-blue !important;
}

.text-danger {
	color: $color-error !important;
}
.text-gray {
  color: $gray;
}

.text-slate-gray {
  color: #4B4D52 !important;
}

.text-disabled {
	color: $color-second;
}

.text-link {
  color: $color-light-blue!important;
}

