section.body {
    &.bg-gray {
        background: $color-gray-1;
        padding-bottom: 3.6rem; } }
button {
    &.btn {
        display: inline-block;
        background-color: #fff;
        vertical-align: middle;
        cursor: pointer;
        white-space: nowrap;
        color: #141414;
        font-weight: bold;
        border: 0 none;
        -webkit-box-shadow: inset 0 0 0 1px #efefef;
        box-shadow: inset 0 0 0 1px #efefef;
        color: $white;
        padding: .6rem 1.2rem;
        font-size: 1.6rem;
        border-radius: .8rem; }
    &.btn-m {
        height: 58px;
        line-height: 58px;
        padding: 0 45px;
        font-size: 1.2rem; }
    &.btn-s {
        height: 40px;
        line-height: 40px;
        padding: 0 45px;
        font-size: 1.2rem; }
    &.btn-xs {
        height: 32px;
        line-height: 32px;
        padding: 0 45px;
        font-size: 1.2rem; }
    &.btn-primary {
        background-color: $color-main;
        border-radius: 8px;
        transition: .3s all ease;
        &:hover, &:focus {
            background-color: $color-main; } }

    &.btn-secondary {
        background-color: $color-light-blue;
        border-radius: 8px;
        transition: .3s all ease;
        font-size: 1.6rem;
        &:hover, &:focus {
            background-color: $color-blue; } }

    &.btn-form-blue {
        background-color: $color-light-blue;
        font-size: 1.2rem;
        &:hover, &:focus {
            color: $white; } }
    &.btn-outline-blue {
        color: $color-light-blue;
        border-radius: 8px;
        transition: .3s all ease;
        font-size: 1.2rem;
        border: 1.5px solid $color-light-blue;
        &:hover, &:focus {
            color: $color-light-blue; } }

    &.btn-social {
        @include poppins(B, 1.4rem);
        width: 100%;
        height: 44px; }
    &.facebook {
        background: #2F80ED;
        color: $white;
        transition: .3s all ease;
        &:hover, &:focus {
            color: $white; } }
    &.google {
        background: $white;
        color: $color-blue;
        &:hover, &:focus {
            box-shadow: inset 0 0 0 1px #efefef !important; } } }
input {
    &.form-control {
        background-color: $white;
        min-height: 58px;
        border-radius: 8px;
        font-size: 1.2rem;
        background-color: $white;
        border: 1px solid #e0e0e0;
        font-size: 1.2rem; }
    &.form-inline {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid $color-main;
        &:focus, &:hover {
            border: none;
            border-bottom: 2px solid $color-main; } } }

.breadcumb {
    &__sect {
        padding: 2.6rem 0;
        .d-flex {
            flex-wrap: wrap; } }
    &__wrapper {
        @media (max-width: 767px) {
            width: 100%; }
        @media (max-width: 480px) {
            margin: 0 15px; }
        a, span {
            color: $gray;
            @include poppins(R, 1rem); } } }


.ct-box__wrapper {
    background: $white;
    border-radius: 1.6rem;
    padding: 2rem 3rem;
    margin-bottom: 2.4rem;
    @media (max-width: 480px) {
        margin: 0 15px;
        margin-bottom: 2.4rem;
        padding: 2rem; }
    .content {
        ul {
            list-style: disc;
            margin-left: 2.5rem; }
        p, ul > li {
            @include poppins(R,1.4rem);
            @media (max-width: 480px) {
                @include poppins(R, 1.2rem); } }
        .ct-desc {
            margin-bottom: 1rem; } } }
h3.s-title {
    @include poppins(B, 2rem);
    color: $color-black;
    margin-bottom: 1.5rem;
    @media (max-width: 480px) {
        @include poppins(B, 1.6rem); } }
.accordion-item:first-of-type, .accordion-item:not(:first-of-type) {
    border-radius: .8rem;
    border: 1px solid rgba(0,0,0,.125); }

.accordion-item {
    margin-bottom: 1.5rem;
    &:last-of-type .accordion-button.collapsed, &:last-of-type .accordion-collapse, &:first-of-type .accordion-button {
        border-radius: .8rem; } }

.accordion-button {
    @include poppins(R, 1.4rem);
    color: $color-black;
    border-radius: .8rem;
    padding: 2rem 2.5rem;
    &:not(.collapsed) {
        color: $color-black;
        background: $white;
        box-shadow: none; }
    &.active {
        background-color: $color-main;
        color: $color-blue; }
    &.not-collapse {
        &:hover, &:focus {
            background-color: $color-main;
            color: $color-blue; } } }

.accordion-body {
    padding-top: 0;
    ul.accordion-list {
        list-style: none;
        margin: 0 2rem;
        li > a {
            padding: 1.5rem 2rem;
            display: block;
            border-radius: .8rem;
            &:hover, &:focus, &.active {
                background-color: $color-main;
                color: $color-blue; } } } }

.plyr--audio .plyr__controls {
    box-shadow: $shadow-1;
    border-radius: 100px;
    padding: 1.5rem; }
.plyr__control, .plyr--full-ui input[type=range] {
    color: $color-blue; }
.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
    background: transparent;
    color: $color-blue; }



.notify-box {
    border-radius: .8rem;
    background: $white;
    padding: 1rem;
    @media (max-width: 767px) {
        width: 100%;
        margin-top: 1rem; }

    p, a {
        color: $color-green-2;
        @include poppins(R, 1.4rem);
        @media (max-width: 767px) {
            @include poppins(R, 1.2rem); } }
    a.s-post {
        margin-left: .5rem;
        color: $color-light-blue;
        text-decoration: underline; } }

