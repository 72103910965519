$background: #e8eaef !default;
// Color Font
$color-blue: #041682 !default;

$color-base: #4F4F4F !default;
$color-theme: #212121 !default;
$color-main: #2EDCAC !default;
$line-green: #25DF7A !default;
$color-green-2: #06916A !default;
$color-bg-green: rgba(164,220,189,.3) !default;
$color-bg-red: rgba(210,197,185,.3) !default;
$color-black: #333333 !default;
$color-orange: #F2994A !default;
$color-red: #EB5757 !default;
$color-light-blue: #00BCEA !default;
$white: #ffffff !default;
$black: #000000 !default;
$gray: #828282 !default;
$background-green: #4DEDC1 !default;


$color-light-main:#CAEEB9 !default;
$color-second: #8350CE !default;
$color-light-second: #D9E0F3 !default;
$color-third: #007BEC !default;
$color-gray: #E0E0E0 !default;
$color-gray-1: #F7F7F7 !default;

$color-light-third: #D3E9F0 !default;
$color-box: #F5F5F5 !default;
$color-white: #ffffff !default;
$color-four: #67737f !default;
$color-five: #ff8996 !default;
$color-border: #efefef !default;
$color-link: #57a8ff !default;

$color-gray-2: #3C4858 !default;
$color-gray-3: #F2F2F2 !default;
$color-gray-4: #BDBDBD !default;
$color-gray-5: #cccccc !default;
$color-gray-6: #6E6E6E !default;
$color-smoke: #E0E6ED !default;
$color-dark-smoke: #D3DCE6 !default;
$color-dark-smoke-ex: #C0CCDA !default;
$color-snow: #F9FAFC !default;
$color-dark-snow: #EFF2F7 !default;
$color-dark-snow-ex: #E5E9F2 !default;
$color-success: #33cc66 !default;
$color-error: #FF0000
!default;
$color-warning: #FFC82C !default;
$color-warning-1: #F44336 !default;

$color-discount: #FF4301;
$color-label: #34495F;

// Font Style

// media screen
$tiny-screen: 349px !default;
$xsmall-screen: 480px !default;
$small-screen: 576px !default;
$medium-screen: 767px !default;
$large-screen: 991px !default;
$xlarge-screen: 1199px !default;
// Page
$base-font: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;
$primary-font: 'Sanomat Sans', 'Encode Sans Expanded',-apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", sans-serif;
$main_sprites:"../images/main_sprites.png";
$main_sprites_size:400px 400px;
$tut_sprites:"../images/tutorial_sprites.png";
$tut_sprites_size:500px 250px;
$faqs_sprites:"../images/icon-faqs.png";
$faqs_sprites_size:500px 500px;
$line-height:1.5;
$max-width:1240px;
$easing: cubic-bezier(0.7, 0, 0.3, 1);

$shadow-1: 0px 4px 30px rgba(0,0,0,.15);
$shadow-2: 0px 4px 100px rgba(0,0,0,.1)
