.login {
    &__sect {
        padding: 10rem 0;
        @media (max-width: 767px) {
            padding: 5rem 0; } }
    &__wrapper {
        max-width: 580px;
        margin: 0 auto;
        @media (max-width: 576px) {
            margin: 0 1.5rem; } }
    &__box {
        background: $white;
        padding: 9rem 7rem;
        box-shadow: $shadow-2;
        border-radius: 1.6rem;
        flex-wrap: wrap;
        @media (max-width: 576px) {
            padding: 4rem 2rem; }
        > span {
            @include poppins(R, 1.4rem);
            color: $gray; }

        .form-control {
            @include poppins(R, 1.4rem);
            min-height: 44px;
            color: $color-black;
            &::placeholder {
                color: $color-gray; } } } }
