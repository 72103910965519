.footer {
	background-color: $color-main;
	color: $color-blue;
	font-size: 1.2rem;
	padding: 10rem 0;
	position: relative;
	@media (max-width: 767px) {
		padding: 6rem 0; }
	&:before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		background-image: url("../images/footer_1.svg");
		width: 225px;
		height: 225px;
		background-repeat: no-repeat; }
	&:after {
		position: absolute;
		content: "";
		bottom: 0;
		right: 0;
		background-image: url("../images/footer_2.svg");
		width: 450px;
		height: 363px;
		background-repeat: no-repeat;
		@media (max-width: 767px) {
			opacity: 1; } }
	.full-footer {
		margin: 0 1.5rem; }
	.logo {
		@media (max-width: 767px) {
			margin-bottom: 2rem; } }
	.footer-menu {
		justify-content: space-between;
		@media (max-width: 767px) {
			flex-wrap: wrap;
			.col-footer {
				width: 33.3%;
				justify-content: space-evenly;
				margin-bottom: 1.5rem; } } }

	h6 {
		@include poppins(semiB, 1.2rem);
		margin-bottom: 1rem; }
	p, a {
		color: $color-blue;
		@include poppins(L, .8rem); }
	.footer-col-1 {
		h6 {
			margin-bottom: 2.5rem;
			font-size: 1.4rem; }

		.info-company, .info-contact {
			p {
				margin-bottom: 1rem; } }
		.info-contact {
			p {
				@extend %align-center; } } }
	.logo-social {
		.link {
			margin-right: 1.5rem; } } }
