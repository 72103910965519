
@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Black.otf');
	font-weight: 900;
	font-style: normal;
}


@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Bold.otf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-ExtraBold.otf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Regular.otf');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Medium.otf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins/Poppins-Light.otf');
	font-weight: 300;
	font-style: normal;
}


@mixin poppins($type, $font_size: 0) {
    font-family: 'Poppins';
    //  $font_size != 0
    //     font-size: $font_size
    @if $font_size != 0 {
      font-size: $font_size;
    }
    @if $type == blackk{
        font-weight: 900;
        font-style: normal;
    }
    @else if $type == semiB {
        font-weight: 800;
        font-style: normal;
    }
    @else if $type == B {
        font-weight: bold;
        font-style: normal;
    }
    @else if $type == M {
        font-weight: 500;
        font-style: normal;
    }
    @else if  $type == R {
        font-weight: normal ;
        font-style: normal;
    }
    @else if $type == L {
        font-weight: 300;
        font-style: normal;
    }

}